import React, { useContext } from 'react'
import { uiConstants } from '../../../../../constants'

import { UserContext } from '../../../../Context/UserContext'
import css from './SwitchTheme.module.scss'

const SwitchTheme = () => {
  const { switchTheme, theme } = useContext(UserContext)

  return (
    <button onClick={switchTheme}>
      <div
        className={`${css.Icon} ${
          theme === uiConstants.themes.light ? css.Day : ''
        }`}
      ></div>
    </button>
  )
}

export default SwitchTheme
