export const uiConstants = {
  dateFormat: 'YYYY MMM DD',
  dateTimeFormat: 'YYYY-MM-DD HH:mm',
  dateTimeSecFormat: 'YYYY-MM-DD HH:mm:ss',
  hourFormat: 'HH:mm',
  dayOfWeek: '0',
  timeToDateInput: 'YYYY-MM-DD',
  themes: {
    light: 'light',
    dark: 'dark'
  }
}
