import React from 'react'

import SwitchTheme from './SwitchTheme/SwitchTheme'
import css from './Menu.module.scss'
import { db } from '../../../../db'
import { timeHelper } from '../../../../helpers/time.helpers'

const Menu = () => {
  const newNoteHandler = async () => {
    await db.notes.add({
      data: '',
      time: timeHelper.now()
    })
  }

  return (
    <ul className={css.UlMenu}>
      <li>
        <button className={css.BtnNew} onClick={newNoteHandler}>
          New Note
        </button>
      </li>
      <li>
        <SwitchTheme />
      </li>
    </ul>
  )
}

export default Menu
