import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { db } from '../../db'
import css from './Writer.module.scss'
import { timeHelper } from '../../helpers/time.helpers'

const Writer = () => {
  const [input, setInput] = useState('')
  let params = useParams()

  const handleChange = async (e) => {
    setInput(e.target.value)

    const count = await db.notes
      .where('id')
      .equals(parseInt(params['*']))
      .count()
    if (count === 0) {
      await db.notes.add({
        id: parseInt(params['*']),
        data: e.target.value,
        time: timeHelper.now()
      })
    } else {
      db.notes.update(parseInt(params['*']), { data: e.target.value })
    }
  }

  useEffect(() => {
    if (parseInt(params['*']) > 0) {
      db.notes.get(parseInt(params['*'])).then((note) => {
        if (note) {
          setInput(note.data)
        }
      })
    }
  }, [params])

  return (
    <ul className={css.UlArea}>
      <li className={css.LiRows}>
        {input.split('\n').map((i, index) => (
          <span className={css.RowNum} key={index}>
            {index + 1}
          </span>
        ))}
      </li>
      <li className={css.LiArea}>
        <textarea
          disabled={params['*'] === ''}
          style={{ height: input.split('\n').length * 20 + 10 }}
          value={input}
          onChange={(e) => handleChange(e)}
        ></textarea>
      </li>
    </ul>
  )
}

export default Writer
