import React, { useEffect } from 'react'
import { Outlet, useLocation } from 'react-router-dom'

import css from './Layout.module.scss'
import Menu from './Menu/Menu'
import List from './List/List'

const Layout = () => {
  const location = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [location])

  return (
    <React.Fragment>
      <div className={css.LiList}>
        <Menu />
        <List />
      </div>
      <div className={css.LiWriter}>
        <Outlet />
      </div>
    </React.Fragment>
  )
}

export default Layout
