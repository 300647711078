import React from 'react'
import { Route, Routes, BrowserRouter } from 'react-router-dom'

import Writer from './components/Writer/Writer'

import UserProvider from './components/Context/UserContext'
import Layout from './components/Containers/Layout/Layout'

function App() {
  return (
    <BrowserRouter>
      <UserProvider>
        <Routes>
          <Route element={<Layout />}>
            <Route path='/*' element={<Writer />} />
          </Route>
        </Routes>
      </UserProvider>
    </BrowserRouter>
  )
}

export default App
