import React from 'react'
import { Link, useParams } from 'react-router-dom'
import { useLiveQuery } from 'dexie-react-hooks'

import css from './List.module.scss'
import { db } from '../../../../db'
import { timeHelper } from '../../../../helpers/time.helpers'

const List = () => {
  const params = useParams()
  const notes = useLiveQuery(() => db.notes.toArray())

  const removeNoteHandler = (id) => {
    db.notes.delete(id)
  }

  return (
    <ul className={css.UlList}>
      {(notes || []).map((n) => (
        <li
          key={n.id}
          className={params['*'] === n.id.toString() ? css.Active : ''}
        >
          <Link to={'/' + n.id} className={css.Link}>
            <span>{timeHelper.dateToFormat(n.time)}</span>
            {n.data.substring(0, 40)}
            {n.data.length > 40 && '...'}
            {n.data.length === 0 && (
              <span className={css.Empty}>This note is empty</span>
            )}
          </Link>
          <button
            onClick={() => removeNoteHandler(n.id)}
            className={css.Remove}
          >
            <i className='fa-solid fa-trash'></i>
          </button>
        </li>
      ))}
    </ul>
  )
}

export default List
